import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { IconEmailAttention, PrimaryButton } from '..';
import { useHistory } from 'react-router-dom';

import css from './ModalMissingInformation.module.css';

const UserSchoolsReminder = props => {
  const {
    className,
  } = props;

  const history = useHistory();

  const handleGoToProfileSettingsPage = () => {
    history.push('/profile-settings')
  }

  return (
    <div className={className}>
      <IconEmailAttention className={css.modalIcon} />
      <p className={css.modalTitle}>
        <FormattedMessage id="ModalMissingInformation.updateUserSchoolsTitle" />
      </p>
      <p className={css.modalMessage}>
        <FormattedMessage id="ModalMissingInformation.updateUserSchoolsText" />
      </p>
      <p className={css.modalMessage}>
        <FormattedMessage id="ModalMissingInformation.checkProfileSettings" />
      </p>

      <div className={css.bottomWrapper}>
        <PrimaryButton onClick={handleGoToProfileSettingsPage}>
          <FormattedMessage id="ModalMissingInformation.gotoProfileSettingsPage"/>
        </PrimaryButton>
      </div>
    </div>
  );
};

export default UserSchoolsReminder;
