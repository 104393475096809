import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { subscribeWeeklyListingNews } from '../../util/api';

// ================ Action types ================ //

export const SAVE_NOTIFICATIONS_REQUEST = 'app/NotificationSettingsPage/SAVE_NOTIFICATIONS_REQUEST';
export const SAVE_NOTIFICATIONS_SUCCESS = 'app/NotificationSettingsPage/SAVE_NOTIFICATIONS_SUCCESS';
export const SAVE_NOTIFICATIONS_ERROR = 'app/NotificationSettingsPage/SAVE_NOTIFICATIONS_ERROR';

// ================ Reducer ================ //

const initialState = {
  saveNotificationsInprogress: false,
  saveNotificationsError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SAVE_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        saveNotificationsInprogress: true,
        saveNotificationsError: null,
      };
    case SAVE_NOTIFICATIONS_SUCCESS:
      return { ...state, saveNotificationsInprogress: false };
    case SAVE_NOTIFICATIONS_ERROR:
      return { ...state, saveNotificationsInprogress: false, saveNotificationsError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const saveNotificationsRequest = () => ({ type: SAVE_NOTIFICATIONS_REQUEST });
export const saveNotificationsSuccess = () => ({ type: SAVE_NOTIFICATIONS_SUCCESS });
export const saveNotificationsError = error => ({
  type: SAVE_NOTIFICATIONS_ERROR,
  payload: error,
  error: true,
});

// ================ Thunks ================ //

/**
 * Make a phone number update request to the API and return the current user.
 */
export const saveNotificationSettings = params => (dispatch, getState, sdk) => {
  dispatch(saveNotificationsRequest())
  const { notifications } = params || {}

  return sdk.currentUser
    .updateProfile(
      { privateData: { notifications } },
      {
        expand: true,
        include: ['profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      }
    )
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
      }

      dispatch(saveNotificationsSuccess())
      subscribeWeeklyListingNews();
      const currentUser = entities[0];
      return currentUser;
    })
    .catch(e => {
      dispatch(saveNotificationsError(storableError(e)));
      // pass the same error so that the SAVE_CONTACT_DETAILS_SUCCESS
      // action will not be fired
      throw e;
    });
};

export const loadData = () => {
  // Since verify email happens in separate tab, current user's data might be updated
  return fetchCurrentUser();
};
